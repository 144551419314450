import {CommonFormComponentProps} from "../../../../types/negotiations/components";
import {Button, Col, Form, Input, Radio, Row, Space, Tooltip, Typography} from "antd";
import React, {useMemo} from "react";
import AddAgreementRow from "./AddAgreementRow";
import AgreementRows from "./agreementRows";
import {States} from "../../../../types";
import {
    useCreateRelateNegotiationsMutation,
    useGetAgreementRowsQuery
} from "../../../../redux/api/agreementRowApiSlice";
import InfoModal from "../../../modals/InfoModal";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import useCanModifyForm from "../../../../hooks/permissions/useCanModifyForm";

export default function AgreementMaster({
                                            formInstance,
                                            negotiation,
                                            onFormValuesChange,
                                            setCalculateField,
                                            disabled,
                                            saveData,
                                        }: Omit<CommonFormComponentProps, 'forwaredRef'>) {

    const isFrameworkAgreement = negotiation.is_framework_agreement
    const [generateNegotiations, {isLoading}] = useCreateRelateNegotiationsMutation()
    const {data, isFetching, isUninitialized} = useGetAgreementRowsQuery(isFrameworkAgreement ? {
        negotiationUuid: negotiation.uuid,
        page: 1,
        page_size: 10,
    } : skipToken)

    const isWithoutAgreements = !isFrameworkAgreement || data?.count === 0
    const isDisabled = useMemo(() => ![
                States.Bound,
                States.Draft,
                States.Issued,
                States.InRinnovo,
                States.DaRinnovare,
                States.Stornata,
                States.NonRinnovata
            ]
                .includes(negotiation.state)
            || isFetching || isUninitialized
            || isWithoutAgreements
            || disabled
        , [isWithoutAgreements, disabled, isFetching, isUninitialized, negotiation.state])

    const canModifyForm = useCanModifyForm(negotiation.lob.uuid)

    return <>
        <Form
            form={formInstance}
            disabled={disabled}
            name='agreements'
            layout="vertical"
            requiredMark={false}
            onValuesChange={onFormValuesChange}
        >
            <Row justify={"space-between"} align={'bottom'}>
                {isWithoutAgreements && <Col xs={24} sm={11}>
                    <Form.Item name='is_framework_agreement' label="È presente una convenzione?">
                        <Radio.Group>
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>}
                {!isWithoutAgreements && <Col xs={24} sm={11}>
                    <Form.Item
                        name='is_framework_agreement'
                        label={(
                            <Space align={'center'} size={'small'}>
                                <Typography.Text>È presente una convenzione?</Typography.Text>
                                {canModifyForm && <InfoModal
                                    title='Convenzione'
                                    description={'Per eliminare la convenzione è necessario prima eliminare le righe della tabella sottostante'}/>}
                            </Space>
                        )}>
                        <strong>Si</strong>
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={11}>
                    <Form.Item label="Convenzione" name='framework_agreement' hidden={!isFrameworkAgreement}>
                        <Input placeholder={disabled ? "" : "Convenzione"}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        {isFrameworkAgreement && <>
            <AgreementRows negotiation={negotiation} disabled={disabled}/>
            {canModifyForm &&
                <Row justify={'space-between'} style={{marginTop: '1rem'}}>
                    <Col>
                        <AddAgreementRow negotiation={negotiation} disabled={disabled}/>
                    </Col>
                    <Col>
                        {isDisabled ?
                            <Tooltip title={'La generazione non è possibile nello stato corrente della trattativa'}>
                                <Button
                                    onClick={() => generateNegotiations({negotiationUuid: negotiation.uuid})}
                                    loading={isLoading}
                                    type={'primary'}
                                    disabled={isDisabled}>
                                    Genera trattative
                                </Button>
                            </Tooltip> : <Button
                                onClick={() => generateNegotiations({negotiationUuid: negotiation.uuid})}
                                loading={isLoading}
                                type={'primary'}
                                disabled={isDisabled}>
                                Genera trattative
                            </Button>}

                    </Col>
                </Row>}
        </>}
    </>
}