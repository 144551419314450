import React, {useCallback, useEffect, useState} from "react";
import {List, Row, Col, Select, Input, Tag, Space, Button, Divider, Pagination} from "antd";
import {useCreateMeetingNoteMutation, useDeleteMeetingNotesMutation, useLazyGetMeetingNotesQuery} from "../../redux/api/commercialDetailApiSlice";
import {useSelector} from "react-redux";
import MeetingNoteItem from "./MeetingNoteItem";
import {PlusOutlined} from "@ant-design/icons";
import {canModifyCommercialDetail} from "../../utils/permission";
import {selectUser} from "../../redux/features/userSlice";
import DatePicker from "../DatePicker";
import dayjs from "dayjs";
import {selectOptions} from "../../redux/features/optionsSlice";

const pageSize = 4

export default function MeetingNotes({broker, bankBranch}: { broker?: string, bankBranch?: string }) {
    const lobs = useSelector(selectOptions).lobs
    const user = useSelector(selectUser)

    const [getMeetingNotesData, {data: meetingNotesData, isLoading, isFetching}] = useLazyGetMeetingNotesQuery()
    const [createMeetingNoteRequest] = useCreateMeetingNoteMutation()
    const [deleteMeetingNote] = useDeleteMeetingNotesMutation()

    const [newNote, setNewNote] = useState<any>({meeting_date: dayjs(), note: "", lob: []})
    const [createNewNote, setCreateNewNote] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const fetchMeetingNotes = useCallback(() => {
        if (broker)
            getMeetingNotesData({broker, page_size: pageSize, page: currentPage})
        if (bankBranch)
            getMeetingNotesData({bank_branch: bankBranch, page_size: pageSize, page: currentPage})
    }, [bankBranch, broker, currentPage, getMeetingNotesData])

    useEffect(() => {
        fetchMeetingNotes()
    }, [fetchMeetingNotes])

    const createNote = useCallback(()=> {
        if (broker)
            createMeetingNoteRequest({...newNote, meeting_date: newNote.meeting_date.format("YYYY-MM-DD"), broker})
        if (bankBranch)
            createMeetingNoteRequest({...newNote, meeting_date: newNote.meeting_date.format("YYYY-MM-DD"), bank_branch: bankBranch})
        setNewNote({meeting_date: dayjs(), note: "", lob: []})
        setCreateNewNote(false)
        setCurrentPage(1)
    }, [bankBranch, broker, createMeetingNoteRequest, newNote])

    const deleteNote = useCallback((uuid: string) => {
        deleteMeetingNote({uuid})
    }, [deleteMeetingNote])


    return <div>
        {canModifyCommercialDetail(user.usertypes.map(el=>el.code)) && !createNewNote && <Row justify={"end"} style={{marginBottom: "12px"}}>
            <Button type={"primary"} onClick={()=> setCreateNewNote(true)} icon={<PlusOutlined/>}>Crea nota</Button>
        </Row>}
        {canModifyCommercialDetail(user.usertypes.map(el=>el.code)) && createNewNote && <div>
            <Row justify={"center"} style={{marginBottom: "12px"}}>
                <strong>Inserisci nuova nota</strong>
            </Row>
            <Row justify={"space-between"} style={{marginBottom: "1rem"}}>
                <Col span={10}>
                    LOB
                    <Select
                        value={newNote.lob}
                        mode={"multiple"}
                        virtual={false}
                        showArrow
                        style={{width: "100%"}}
                        options={lobs.map(el => ({label: el.name, value: el.uuid}))}
                        onChange={value => setNewNote({...newNote, lob: value})}
                    />
                </Col>
                <Col span={10}>
                    Data incontro
                    <DatePicker
                        style={{width: "100%"}}
                        value={newNote.meeting_date}
                        format={'D MMM YYYY'}
                        onChange={(date) => setNewNote({...newNote, meeting_date: date})}
                    />
                </Col>
            </Row>
            <Input.TextArea
                value={newNote.note}
                autoSize={{minRows: 7, maxRows: 7}}
                onChange={event => setNewNote({...newNote, note: event.target.value})}
            />
            <Row justify={"end"} style={{marginTop: "1rem"}}>
                <Space>
                    <Button onClick={()=> setCreateNewNote(false)}>
                        Annulla
                    </Button>
                    <Button type={"primary"} onClick={() => createNote()} disabled={newNote.note.trim() === "" || !newNote.meeting_date}>
                        Crea
                    </Button>
                </Space>
            </Row>
            <Divider/>
        </div>}
        <Row justify={"center"} style={{marginBottom: "12px"}}>
            <strong>Note presenti</strong>
        </Row>
        {meetingNotesData && meetingNotesData.results?.length > 0 &&
            <div>
                <div className={"scrollable-div"}>
                    <List
                        loading={isLoading || isFetching}
                        dataSource={meetingNotesData?.results}
                        renderItem={item => <MeetingNoteItem item={item} lobs={lobs} deleteNote={() => deleteNote(item.uuid)} currentPage={currentPage}/>}
                    />
                </div>
                <Row justify={"end"}>
                    <Pagination
                        total={meetingNotesData.count}
                        simple
                        current={currentPage}
                        onChange={(page) => setCurrentPage(page)}
                        showSizeChanger={false}
                        pageSize={pageSize}/>
                </Row>
            </div>}
        {meetingNotesData && meetingNotesData.results?.length === 0 && <Row justify={"center"}><p>Nessuna nota presente</p></Row>}
    </div>
}