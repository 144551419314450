import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {States, UserTypes} from "../../types";
import {selectOptions} from "../../redux/features/optionsSlice";
import {NegotiationModel} from "../../types/negotiations";

export default function useCanModifyUnderwriterInCharge(negotiation?: NegotiationModel) {

    const user = useSelector(selectUser);
    const userTypes = user.usertypes.map(el => el.code)
    const lobs = useSelector(selectOptions).lobs
    const isConditionMet = userTypes.some(el => {
        let result = false
        switch (el) {
            case UserTypes.UW:
            case UserTypes.UWJ:
            case UserTypes.UWS:
            case UserTypes.LBM:
                if (negotiation?.lob) {
                    const lob = lobs.find(currentLob => currentLob.uuid === negotiation.lob.uuid)
                    result = !!(
                        (negotiation?.state && negotiation.state >= States.Assegnata) &&
                        (lob?.managers.some(el => el.uuid === user.uuid) || lob?.underwriters.some(el => el.uuid === user.uuid))
                    )
                }
                break;
            case UserTypes.ADMIN:
            case UserTypes.CM:
                result = !!(negotiation?.state && negotiation.state >= States.Assegnata)
                break;
        }

        return result

    })

    return isConditionMet
}