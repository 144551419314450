import {Card, Col, Form, Input, InputNumber, Radio, Row, Tooltip, Typography,} from 'antd';
import {CommonFormComponentProps} from "types/negotiations/components";


import {SolutionOutlined,} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {AntCurrencyFormatInput} from "components/inputNumber";
import TextArea from "antd/es/input/TextArea";
import AppendixFormItem from "../AppendixFormItem";
import React, {useState} from "react";
import WarningTitleButton from "../../buttons/WarningTitleButton";
import {checkStateCorrectForWarningPolicyFields} from "../../../utils/formatters";
import {ReinsuranceType} from "../../../types/reinsuranceType";

const {Title} = Typography

export default function PoliciesAndIssues({
                                              forwaredRef,
                                              formInstance,
                                              negotiation,
                                              onFormValuesChange,
                                              disabled,
                                              setCalculateField,
                                          }: CommonFormComponentProps) {

    const {installmentTypes, lobs} = useSelector(selectOptions)
    const [disabledPolicyFields, setDisabledPolicyFields] = useState({installment_type: true})

    const watchIsFrameworkAgreement = Form.useWatch('is_framework_agreement', formInstance);
    const watchInstallmentType = Form.useWatch('installment_type', formInstance)
    const watchHasRegulationPremium = Form.useWatch('has_regulation_premium', formInstance)
    const watchReinsuranceType = Form.useWatch('reinsurance_type', formInstance)

    const currentLobId = formInstance.getFieldValue('lob')
    const currentLob = lobs.find(lob => lob.uuid === currentLobId)
    const isPropertyLob = !!(currentLob && ['property'].includes(currentLob.name.toLowerCase()))
    const isStateCorrectForWarningPolicyFields = checkStateCorrectForWarningPolicyFields(negotiation.state)

    return (
        <>
            <Card ref={forwaredRef} id="policiesAndIssues" bordered={false} style={{
                borderRadius: '12px',
                width: '100%'
            }}>
                <Form
                    form={formInstance}
                    disabled={disabled}
                    name='policiesAndIssues'
                    layout="vertical"
                    requiredMark={false}
                    onValuesChange={onFormValuesChange}
                >
                    <Row style={{marginBottom: '24px'}}>
                        <Col>
                            <Title level={3}>
                                <SolutionOutlined/>
                            </Title>
                        </Col>
                        <Col style={{marginLeft: '12px'}}>
                            <Title level={3}>Polizze ed emissioni</Title>
                        </Col>
                    </Row>
                    <Row justify={"space-between"} align={'bottom'}>
                        <Col xs={24} sm={11}>
                            <Form.Item name='has_regulation_premium' label="Regolazione premio">
                                <Radio.Group>
                                    <Radio value={true}>Sì </Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={11}>
                            <Form.Item label='Numero polizza' name='policy_number'>
                                {negotiation.policy_number ?
                                    <span style={{fontWeight: 'bold'}}>{negotiation.policy_number}</span> :
                                    <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Non presente</span>}
                            </Form.Item></Col>
                        <Col xs={24} md={11}>
                            <Form.Item label='Numero proposta caricata su Pass'>
                                <Typography.Text
                                    strong
                                    underline={!negotiation.has_proposal_number}>
                                    {negotiation.proposal_number ?? 'Non presente'}
                                </Typography.Text>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align='middle' justify='space-between'>
                        <Col style={{width: '100%'}}>
                            <Form.Item
                                name='installment_type'
                                label={
                                    isStateCorrectForWarningPolicyFields && !disabled ?
                                        <WarningTitleButton
                                            title={"Frazionamento premio"}
                                            warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                            onConfirm={() => setDisabledPolicyFields(prevState => ({
                                                ...prevState,
                                                installment_type: !prevState.installment_type
                                            }))}
                                            isEditing={!disabledPolicyFields.installment_type}
                                        />
                                        :
                                        "Frazionamento premio"
                                }
                            >
                                <Radio.Group
                                    disabled={disabled || (isStateCorrectForWarningPolicyFields && disabledPolicyFields.installment_type)}>
                                    {installmentTypes.map(type => <Radio key={type.value}
                                                                         value={type.value}>{type.text} </Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col style={{width: '100%'}}>
                            <Form.Item label="Numero frazionamenti premio" name='installments_number'
                                       hidden={!(watchInstallmentType === 'O')}>
                                <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                            <Tooltip placement='top'
                                     title={formInstance.getFieldValue('is_calculated')?.policy_premium ? 'Premio imponibile 100%' : null}>
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.policy_premium !== curValues.is_calculated?.policy_premium}
                                    style={{margin: 0, padding: 0}}>
                                    {() => <Form.Item label="Premio imponibile totale" name='policy_premium'>
                                        <AntCurrencyFormatInput
                                            disabled={formInstance.getFieldValue('is_calculated')?.policy_premium}
                                            hasPlaceholder={!disabled}
                                            calculate={() => setCalculateField('policy_premium')}/>
                                    </Form.Item>}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} md={11}>
                            {isPropertyLob && (
                                <Tooltip placement='top'
                                         title={formInstance.getFieldValue('is_calculated')?.policy_insured_sum ? 'Somma assicurata/limite * (% ns quota)' : null}>
                                    <Form.Item
                                        shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.policy_insured_sum !== curValues.is_calculated?.policy_insured_sum}
                                        style={{margin: 0, padding: 0}}>
                                        {() => <Form.Item label="Importo assicurato (nostra quota)"
                                                          name='policy_insured_sum'>
                                            <AntCurrencyFormatInput
                                                disabled={!!formInstance.getFieldValue('is_calculated')?.policy_insured_sum || disabled}
                                                hasPlaceholder={!disabled}
                                                calculate={() => setCalculateField('policy_insured_sum')}
                                            />
                                        </Form.Item>}
                                    </Form.Item>
                                </Tooltip>
                            )}
                            {!isPropertyLob && (
                                <Form.Item label="Importo assicurato (nostra quota)" name='policy_insured_sum'>
                                    <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                                </Form.Item>
                            )}
                        </Col>
                        {watchReinsuranceType === ReinsuranceType.FACOLTATIVO && (
                            <Col xs={24} md={11}>
                                <Tooltip
                                    placement='top'
                                    title={formInstance.getFieldValue('is_calculated')?.policy_insured_sum_net_reins ? 'Importo assicurato (nostra quota) * (1 - % Riassicurazione)' : null}>
                                    <Form.Item
                                        shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.policy_insured_sum_net_reins !== curValues.is_calculated?.policy_insured_sum_net_reins}
                                        style={{margin: 0, padding: 0}}>
                                        {() => <Form.Item
                                            label="Importo Assicurato (nostra quota) al netto della riassicurazione"
                                            name='policy_insured_sum_net_reins'>
                                            <AntCurrencyFormatInput
                                                disabled={!!formInstance.getFieldValue('is_calculated')?.policy_insured_sum_net_reins}
                                                hasPlaceholder={!disabled}
                                                calculate={() => setCalculateField('policy_insured_sum_net_reins')}
                                            />
                                        </Form.Item>}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        )}
                    </Row>
                    {watchHasRegulationPremium &&
                        <Form.Item label="Premio di regolazione (nostra quota)" name='regulation_premium'>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                        </Form.Item>}
                    <AppendixFormItem fee={negotiation.isa_share_perc || 0} negotiation={negotiation.uuid}/>
                    <Form.Item label="Note" name='policy_notes'>
                        <TextArea autoSize={{minRows: 3}} placeholder={disabled ? "" : "Note"}/>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}