import React, {useCallback, useEffect, useState} from "react";
import {useCreateAgreementsMutation, useDeleteAgreementMutation, useLazyGetAgreementsQuery} from "../../redux/api/brokersApiSlice";
import {Button, Col, Divider, Input, InputNumber, List, Pagination, Radio, Row, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import AgreementItem from "./AgreementItem";
import {AntCurrencyFormatInput} from "../inputNumber";
import {CreateAgreementParams} from "../../types/broker";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";

const pageSize = 5

export default function BrokerAgreements({broker}: { broker: string }) {
    const lobs = useSelector(selectOptions).lobs

    const [getAgreementsData, {data: agreementsData, isLoading, isFetching}] = useLazyGetAgreementsQuery()
    const [createAgreementRequest] = useCreateAgreementsMutation()
    const [deleteAgreementRequest] = useDeleteAgreementMutation()

    const [newAgreement, setNewAgreement] = useState<CreateAgreementParams>({
        broker,
        commissions: undefined,
        name: "",
        lob: null,
        dedicated_wording: false})
    const [createNewAgreement, setCreateNewAgreement] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const fetchAgreements = useCallback(() => {
        if (broker) {
            getAgreementsData({broker, page_size: pageSize, page: currentPage})
        }
    }, [broker, currentPage, getAgreementsData])

    useEffect(() => {
        fetchAgreements()
    }, [fetchAgreements])

    const createAgreement = useCallback(() => {
        createAgreementRequest(newAgreement)
        setCreateNewAgreement(false)
        setCurrentPage(1)
    }, [createAgreementRequest, newAgreement])

    const deleteAgreement = useCallback((uuid: string | null) => {
        return deleteAgreementRequest({uuid: uuid ? uuid : ""})
    }, [deleteAgreementRequest])

    return <div>
        {!createNewAgreement && <Row justify={"end"} style={{marginBottom: "12px"}}>
            <Button type={"primary"} onClick={() => setCreateNewAgreement(true)} icon={<PlusOutlined/>}>Crea convenzione</Button>
        </Row>}
        {createNewAgreement && <div>
            <Row justify={"center"} style={{marginBottom: "12px"}}>
                <strong>Inserisci nuova convenzione</strong>
            </Row>
            <Row justify={"space-between"} style={{marginBottom: "1rem"}} gutter={[8,8]}>
                <Col span={10}>
                    Nome
                    <Input
                        style={{width: "100%"}}
                        onChange={(event)=> setNewAgreement({...newAgreement, name: event.target.value})}
                    />
                </Col>
                <Col span={10}>
                    LOB
                    <Select
                        value={newAgreement.lob}
                        virtual={false}
                        showArrow
                        style={{width: "100%"}}
                        options={lobs.map(el => ({label: el.name, value: el.uuid}))}
                        onChange={value => setNewAgreement({...newAgreement, lob: value})}
                    />
                </Col>
                <Col span={10}>
                    Commissioni
                    <AntCurrencyFormatInput
                        suffix={' %'}
                        onChange={v => setNewAgreement({...newAgreement, commissions: typeof v === 'number' ? v : null})}
                        value={typeof newAgreement.commissions === 'number' ? newAgreement.commissions : undefined}
                        max={100}
                        min={0}
                        decimalScale={0}
                        placeholder={'0 %'}
                    />
                </Col>
                <Col span={10}>
                    Wording dedicato <br/>
                    <Radio.Group
                        onChange={(event)=> setNewAgreement({...newAgreement, dedicated_wording: event.target.value})}
                    >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Row justify={"end"} style={{marginTop: "1rem"}}>
                <Space>
                    <Button onClick={()=> setCreateNewAgreement(false)}>
                        Annulla
                    </Button>
                    <Button
                        type={"primary"}
                            onClick={createAgreement}
                        disabled={newAgreement.name.trim() === "" || !newAgreement.lob}
                    >
                        Crea
                    </Button>
                </Space>
            </Row>
            <Divider/>
        </div>}
        <Row justify={"center"} style={{marginBottom: "12px"}}>
            <strong>Convenzioni presenti</strong>
        </Row>
        {agreementsData && agreementsData?.results.length > 0 &&
            <div>
                <div className={"scrollable-div"}>
                    <List
                        loading={isLoading || isFetching}
                        dataSource={agreementsData.results}
                        renderItem={item => <AgreementItem item={item} lobs={lobs} deleteAgreement={() => deleteAgreement(item.uuid)} currentPage={currentPage}/>}/>
                </div>
                <Row justify={"end"}>
                    <Pagination
                        total={agreementsData?.count}
                        simple
                        current={currentPage}
                        onChange={(page) => setCurrentPage(page)}
                        showSizeChanger={false}
                        pageSize={pageSize}/>
                </Row>
            </div>}
        {agreementsData && agreementsData?.results.length === 0 && <Row justify={"center"}><p>Nessuna convenzione presente</p></Row>}
    </div>
}