import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {UserTypes} from "../../types";
import {selectOptions} from "../../redux/features/optionsSlice";

export default function useCanUploadNegotiationDocuments(negotiationLob?: string) {

    const user = useSelector(selectUser);
    const userTypes = user.usertypes.map(el => el.code)
    const lobs = useSelector(selectOptions).lobs
    const isConditionMet = userTypes.some(el => {
        let result = false
        switch (el) {
            case UserTypes.UW:
            case UserTypes.UWJ:
            case UserTypes.UWS:
            case UserTypes.LBM:
                if (negotiationLob) {
                    const lob = lobs.find(currentLob => currentLob.uuid === negotiationLob)
                    result = !!(lob?.managers.some(el => el.uuid === user.uuid) || lob?.underwriters.some(el => el.uuid === user.uuid))
                }
                break;
            case UserTypes.ADMIN:
            case UserTypes.CM:
            case UserTypes.PO:
            case UserTypes.PM:
                result = true
                break;
        }

        return result

    })

    return isConditionMet
}