import React from 'react';
import {Button, Card, Col, Divider, message, notification, Row, Space, Spin, Switch,} from 'antd';
import {CalendarOutlined, FileOutlined,} from '@ant-design/icons';
import {NegotiationModel, UpdateNegotiationRequest} from 'types/negotiations';
import {useSelector} from 'react-redux';
import {useExtendNegotiationMutation} from 'redux/api/negotiationsApiSlice';
import {useNavigate, useParams} from "react-router-dom";
import {selectUser} from 'redux/features/userSlice';
import {
    canSeeCoverageConfirmation,
    canExtendNegotiation,
    canGoToSubmission,
    canSeeDocuments,
    canSeeReferralButton
} from "../../../../utils/permission";
import {States} from "../../../../types";
import {ConflictOfInterest} from "./modals/ConflictOfInterest";
import NegotiationDetailList from "./NegotiationDetailList";
import NegotiationStatus from "./NegotiationStatus";
import AssignNegotiationModalButton from "./modals/AssignNegotiationModalButton";
import EditNegotiationModalButton from "./modals/EditNegotiationModalButton";
import EditReferredModalButton from "./modals/EditReferredModalButton";
import MoveNegotiationStatusModalButton from "./modals/MoveNegotiationStatusModalButton";
import DeleteNegotiationModalButton from "./modals/DeleteNegotiationModalButton";
import OpenAssumptionChecklistModalButton from "./modals/OpenAssumptionChecklistModalButton";
import _, {isEmpty} from "lodash";
import useCanModifyForm from "../../../../hooks/permissions/useCanModifyForm";
import useCanDeleteNegotiation from "../../../../hooks/permissions/useCanDeleteNegotiation";
import useCanSeeCheckList from "../../../../hooks/permissions/useCanSeeCheckList";

export type NegotiationStatusViewBaseProps = {
    negotiation: NegotiationModel,
    createStateTransition: (data: {
        state_to: number,
        reason?: string,
        notes?: string
    }, newNegotiation?: NegotiationModel) => Promise<void>,
    isStateTransitionInProgress: boolean
    updateNegotiationUw: (uwId: string) => Promise<NegotiationModel | undefined>
    goToNewNegotiation: (() => void) | null,
    updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>,
    goToDocuments: () => void,
    isRoreSuggestionsVisible?: boolean,
    showRoreSuggestions: () => void,
    hideRoreSuggestions: () => void,
}


export function CompleteNegotiationStatusView({
                                                  negotiation,
                                                  updateNegotiationUw,
                                                  updateNegotiation,
                                                  goToNewNegotiation,
                                                  createStateTransition,
                                                  isStateTransitionInProgress,
                                                  goToDocuments,
                                                  isRoreSuggestionsVisible,
                                                  showRoreSuggestions,
                                                  hideRoreSuggestions
                                              }: NegotiationStatusViewBaseProps
) {

    const {available_state_transitions} = negotiation;
    const availableContainsReferred = available_state_transitions.find(state => state.state === States.Assegnata);

    const {id: negotiationId} = useParams();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const canModifyForm = useCanModifyForm(negotiation.lob.uuid)
    const canDeleteNegotiation = useCanDeleteNegotiation(negotiation.lob.uuid)
    const canSeeCheckList = useCanSeeCheckList(negotiation)
    const [extendNegotiation] = useExtendNegotiationMutation();

    return (
        <Spin
            tip={'Transizione di stato in corso'}
            spinning={isStateTransitionInProgress}>
            <Card
                bordered={false}
                style={{borderRadius: '12px'}}
            >
                <NegotiationStatus
                    negotiation={negotiation}
                    handleStateTransition={createStateTransition}
                    isTransitionInProgress={isStateTransitionInProgress}/>
                <Row gutter={[4, 4]}>
                    {!!goToNewNegotiation && (
                        <Col span={24}>
                            <Button
                                type={"link"}
                                onClick={() => {
                                    goToNewNegotiation();
                                    notification.open({
                                        message: 'Attenzione, sei stato reindirizzato alla nuova trattativa.',
                                        type: 'warning',
                                        duration: 0
                                    })
                                }}>
                                vai alla nuova trattativa
                            </Button>
                        </Col>
                    )}
                    {!!availableContainsReferred ? (
                        <Col span={24}>
                            <AssignNegotiationModalButton
                                negotiation={negotiation}
                                updateNegotiationUnderwriter={updateNegotiationUw}
                                handleCreateStateTransition={createStateTransition}
                                isStateTransitionInProgress={isStateTransitionInProgress}/>
                        </Col>
                    ) : (
                        available_state_transitions.length === 1 ? (
                            <Col span={24}>
                                <MoveNegotiationStatusModalButton
                                    negotiation={negotiation}
                                    handleCreateStateTransition={createStateTransition}
                                    isStateTransitionInProgress={isStateTransitionInProgress}/>
                            </Col>
                        ) : (!!available_state_transitions.length && (
                            <Col span={24}>
                                <EditNegotiationModalButton
                                    negotiation={negotiation}
                                    updateNegotiation={updateNegotiation}
                                    handleCreateStateTransition={createStateTransition}
                                    isStateTransitionInProgress={isStateTransitionInProgress}/>
                            </Col>
                        )))}
                    {
                        (
                            negotiation.state === States.Working &&
                            user &&
                            user.usertypes.length &&
                            canSeeReferralButton(user.usertypes.map(el => el.code)) &&
                            !!negotiation.underwriter
                        ) && (
                            <Col span={24}>
                                <EditReferredModalButton
                                    negotiation={negotiation}
                                    handleCreateStateTransition={createStateTransition}
                                    isStateTransitionInProgress={isStateTransitionInProgress}/>
                            </Col>
                        )
                    }
                </Row>
                <Divider/>
                <Row style={{margin: '1rem 0'}}>
                    <Col span={24}>
                        <NegotiationDetailList
                            negotiation={negotiation}
                            editUnderwriter={updateNegotiationUw}
                            updateNegotiation={updateNegotiation}
                            isRoreSuggestionsVisible={isRoreSuggestionsVisible}
                        />
                    </Col>
                </Row>

                <Row gutter={[4, 4]}>
                    {canModifyForm &&
                        negotiation.state !== States.Rore &&
                        !!negotiation.raw_data && !isEmpty(negotiation.raw_data) && (
                            <Col style={{marginBottom: '0.5rem'}} span={24}>
                                <Space>
                                    Suggerimenti RORE:
                                    <Switch
                                        checked={isRoreSuggestionsVisible}
                                        onChange={value => value ? showRoreSuggestions() : hideRoreSuggestions()}
                                    />
                                </Space>
                            </Col>
                        )}
                    {
                        (
                            negotiation.state !== States.Rore &&
                            !_.isEmpty(user?.usertypes) &&
                            canSeeDocuments(user.usertypes.map(el => el.code))
                        ) && (
                            <Col xs={24} xl={0}>
                                <Button
                                    block={true}
                                    type={"primary"}
                                    ghost={true}
                                    icon={<FileOutlined/>}
                                    onClick={goToDocuments}>
                                    Documenti
                                </Button>
                            </Col>
                        )
                    }
                    {
                        (
                            canSeeCheckList
                        ) && (
                            <>
                                <Col flex={1}>
                                    <OpenAssumptionChecklistModalButton
                                        negotiation={negotiation}
                                        updateNegotiation={updateNegotiation}/>
                                </Col>
                                <Col flex={1}>
                                    <ConflictOfInterest
                                        negotiation={negotiation}
                                        updateNegotiation={updateNegotiation}/>
                                </Col>
                            </>
                        )
                    }

                    {/*negotiation can generate extension*/}
                    {
                        (
                            !_.isEmpty(user?.usertypes) &&
                            canExtendNegotiation(user.usertypes.map(el => el.code)) &&
                            !negotiation.is_renewable &&
                            [States.Bound, States.Draft, States.Issued, States.NonRinnovata].includes(negotiation.state) &&
                            !negotiation.descendant_uuid
                        ) && (
                            <Col span={24}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={async () => {
                                        try {
                                            const extendedNegotiation = await extendNegotiation({uuid: negotiation.uuid}).unwrap()
                                            navigate(`/negotiations/${extendedNegotiation.new_negotiation_uuid}`);
                                            message
                                                .success('Trattativa prorogata con successo')
                                                .then(() => notification.open({
                                                    message: 'Attenzione, sei stato reindirizzato alla trattativa prorogata.',
                                                    type: 'warning',
                                                    duration: 0
                                                }))
                                        } catch {
                                            message.error('Errore nella proroga della trattativa')
                                        }
                                    }}
                                    icon={<CalendarOutlined/>}>
                                    Proroga trattativa
                                </Button>
                            </Col>
                        )
                    }
                    {/*link to negotiation extension*/}
                    {
                        (
                            !negotiation.is_renewable &&
                            [States.Bound, States.Draft, States.Issued, States.NonRinnovata].includes(negotiation.state) &&
                            !!negotiation.descendant_uuid
                        ) && (
                            <Col flex={1}>
                                <Button
                                    onClick={() => {
                                        message.info('Attenzione, sei stato reindirizzato alla trattativa prorogata.')
                                        navigate(`/negotiations/${negotiation.descendant_uuid}`)
                                    }}>
                                    Vai alla proroga
                                </Button>
                            </Col>
                        )
                    }

                    {
                        (
                            !_.isEmpty(user?.usertypes) &&
                            canGoToSubmission(user.usertypes.map(el => el.code)) &&
                            !!negotiation.submission_link
                        ) && (
                            <Col span={24}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={() => {
                                        window.location.href = `${process.env.REACT_APP_URL || 'https://ayakodev.riskapp.it/webapp'}${negotiation.submission_link}`
                                    }}>
                                    Vai alla submission
                                </Button>
                            </Col>
                        )
                    }


                    {(
                        !_.isEmpty(user?.usertypes) &&
                        canSeeCoverageConfirmation(user.usertypes.map(el => el.code)) &&
                        ![States.Declined, States.NotTakenUp, States.ReferralDeclined].includes(negotiation.state) &&
                        negotiation.can_have_coverage_confirmation) && (
                        <Col span={24}>
                            <Button
                                type={"primary"}
                                style={{width: '100%'}}
                                onClick={() => {
                                    navigate(`/negotiations/${negotiationId}/coverageconfirmation`)
                                }}>
                                Quotazione / Conferma di copertura
                            </Button>
                        </Col>
                    )}

                    {canDeleteNegotiation && (
                        <Col span={24}>
                            <DeleteNegotiationModalButton
                                negotiation={negotiation}/>
                        </Col>
                    )}
                </Row>
            </Card>
        </Spin>
    )
}




