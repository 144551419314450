import {Button, ButtonProps, Dropdown, Menu, MenuProps, Select, Space} from "antd";
import {DownloadOutlined, FileExcelOutlined, FileImageOutlined} from "@ant-design/icons";
import React from "react";
import HighchartsReact from "highcharts-react-official";


export default function DownloadButton({
                                           chartRef,
                                           btnProps
                                       }: {
    chartRef?: React.RefObject<HighchartsReact.RefObject>,
    btnProps?: ButtonProps
}) {

    const handleMenuClick: MenuProps['onClick'] = e => {
        switch (e.key) {
            case '1':
                break;
            case '2':
                // @ts-ignore
                if (chartRef && chartRef.current && chartRef.current.chart && chartRef.current.chart.exportChart())
                    // @ts-ignore
                    chartRef.current.chart.exportChart()
                break;
        }
    }

    const menu: MenuProps = {
        onClick: handleMenuClick,
        items: [
            /*{
                label: 'Formato .csv',
                key: '1',
                icon: <FileExcelOutlined/>,
            },*/
            {
                label: 'Formato .png',
                key: '2',
                icon: <FileImageOutlined/>,
            },
        ]
    };

    return <Dropdown menu={menu} {...btnProps}>
        <Button icon={<DownloadOutlined style={{fontSize: '20px'}}/>}
                style={{border: 'none', boxShadow: 'none', cursor: 'pointer'}}>
        </Button>
    </Dropdown>
}