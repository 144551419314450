import {Button, Col, Divider, Row, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {euro} from "utils/formatters";
import GapToPlanNegotiationsModal from "./GapToPlanNegotiationsModal";
import { useSelector} from "react-redux";
import {useLazyGetGapToPlanChartQuery} from "../../redux/api/chartsApiSlice";
import ChartContainer from "../charts/ChartCards/ChartContainer";
import HighchartsReact from "highcharts-react-official";
import BarPercentageChart from "../charts/HighCharts/BarPercentageChart";
import {selectUser} from "../../redux/features/userSlice";
import {Description} from "../charts/ChartCards/GapToPlan";
import UwYearDatePicker from "../select/UwYearDatePicker";
import BudgetGroupSelect from "../select/BudgetGroupSelect";
import IsiaSelect from "../select/IsiaSelect";

export default function GapToPlanNegotiations(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {
    const [showModalGapToPlanModifier, setShowModalGapToPlanModifier] = useState<boolean>(false);
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetGapToPlanChartQuery()

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }

    }, [budgetGroup, getData, isia, uwYear])


    const percentageToDisplay = (data ? data.percentage * 100 : 0).toFixed(2)
    const title = 'Premi su gap to plan'
    return <ChartContainer
        description={<Description/>}
        className={props.className}
        loading={isLoading || isFetching}
        title={title}
        buttons={[<UwYearDatePicker key={'uwYear-picker'}/>, <BudgetGroupSelect key={'budget-group-select'}/>, <IsiaSelect key={'isia-select'} />]}
    >
        <div>
            <BarPercentageChart chartRef={props.chartRef} options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [Number(percentageToDisplay) > 100 ? 0 : 100 - Number(percentageToDisplay)],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: Array.from({length: 1}, () => Number(percentageToDisplay)),
                        color: '#13C2C2',
                        dataLabels: {
                            enabled: true,
                            format: `{y:.2f}%`
                        },
                    },

                ]
            }}/>
            <Divider/>
            <Row justify={'space-between'} wrap={false} align={'bottom'} >
                <Col>Premi per raggiungimento plan:</Col>
                <Col style={{fontWeight: 'bold'}}>{euro(data ? data.goal : 0)}</Col>
            </Row>
            <Row justify={'space-between'} wrap={false} align={'bottom'} >
                <Col>Premi raggiunti:</Col>
                <Col>
                    <span style={{fontWeight: 'bold'}}>{euro(data ? data.amount : 0)}</span>
                </Col>
            </Row>
            <Row justify={'space-between'} wrap={false} align={'bottom'} >
                <Col>Premi new business:</Col>
                <Col
                    style={{fontWeight: 'bolder'}}>{typeof data?.amount_new_business === 'number' ? euro(data.amount_new_business) : euro(0)}</Col>
            </Row>
            <Row justify={'space-between'} wrap={false} align={'bottom'}>
                <Col>Premi rinnovati chiusi con successo:</Col>
                <Col
                    style={{fontWeight: 'bolder'}}>{typeof data?.amount_renews === 'number' ? euro(data.amount_renews) : euro(0)}</Col>
            </Row>
            <Row justify={'space-between'} wrap={false} align={'bottom'} >
                {data?.regulation_premium_factor ?
                    <Col>Stima premi di regolazione {`(${data.regulation_premium_factor})`}:</Col>
                    :
                    <Col>Stima premi di regolazione:</Col>
                }
                <Col style={{fontWeight: 'bolder'}}>{typeof data?.average_regulation === 'number' ? euro(data.average_regulation) : euro(0)}</Col>
            </Row>
            <GapToPlanNegotiationsModal showModalGapToPlanModifier={showModalGapToPlanModifier}
                                        setShowModalGapToPlanModifier={setShowModalGapToPlanModifier}/>
        </div>
        <div style={{textAlign: 'center', paddingTop: '2rem'}}><Button
            type={'primary'}
            onClick={() =>
                setShowModalGapToPlanModifier(true)
            }> Visualizza Budget</Button></div>
    </ChartContainer>
}