import {useSelector} from "react-redux";
import {selectUser} from "../redux/features/userSlice";
import {selectOptions} from "../redux/features/optionsSlice";
import {UserTypes} from "../types";

export default function useGetBelongingLobs() {

    const user = useSelector(selectUser);
    const userUuid = user?.uuid;
    const userType = user?.usertypes
    const lobs = useSelector(selectOptions).lobs.filter(lob =>
        !userType.some(type => [UserTypes.UWJ, UserTypes.UW, UserTypes.UWS, UserTypes.LBM].includes(type.code) ) ||
        (lob.managers.some(user => user.uuid === userUuid)
        ||
        lob.underwriters.some(user => user.uuid === userUuid))
    )

    return lobs
}