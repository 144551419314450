import axios from "axios";
import {Button, Col, Row, Card, Tree, Dropdown, TreeProps, message, Space, Spin, Divider, Empty, Drawer, Tooltip} from "antd";
import {
    FileAddOutlined,
    FileOutlined,
    FolderOpenOutlined,
    LeftOutlined,
    SearchOutlined,
    UnorderedListOutlined,
    CloudUploadOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined
} from "@ant-design/icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {cloneDeep} from "lodash";
import UploadDocumentModal, {UploadDoc} from "../../components/documents/UploadDocumentModal";
import DropDownMenu from "../../components/documents/DropDownMenu";
import DocumentHelpPopover from "../../components/popover/DocumentHelpPopover";
import {
    useDeleteDocumentMutation,
    useLazyGetFolderLabelQuery,
    useLazyGetDocumentsQuery,
    useLazyGetFoldersStructureQuery,
    useLazyRequestToSaveDocumentsQuery,
    useUpdateDocumentMutation,
    useLazyGetDocumentUrlQuery,
    useLazyGetNegotiationHistoryQuery
} from "../../redux/api/documentsApiSlice";
import {CustomDataNode, DocumentListModel, GetDocumentsModel, TreeStructureModel} from "../../types/documents";
import _ from 'lodash';
import SearchDocumentModal from "../../components/documents/SearchDocumentModal";
import RemoveDocumentModal from "../../components/documents/RemoveDocumentModal";
import {useLazyGetNegotiationDetailQuery} from "../../redux/api/negotiationsApiSlice";
import DocumentSidebar from "../../components/documents/DocumentSidebar";
import {States,} from "../../types";
import TitleRender from "../../components/documents/TitleRender";
import {useDropzone} from "react-dropzone";
import useCanUploadNegotiationDocuments from "../../hooks/permissions/useCanUploadNegotiationDocuments";

export default function Documents() {
    const {id: negotiationId} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [getFoldersStructure, {data: foldersStructureData}] = useLazyGetFoldersStructureQuery()
    const [getFoldersLabel, {data: foldersLabelData}] = useLazyGetFolderLabelQuery()
    const [getHistory, {data: historyData}] = useLazyGetNegotiationHistoryQuery()
    const [getDocuments, {isLoading: isDocumentLoading, isFetching: isDocumentFetching}] = useLazyGetDocumentsQuery()
    const [saveDocument] = useLazyRequestToSaveDocumentsQuery()
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const [updateDocument] = useUpdateDocumentMutation()
    const [deleteDocument] = useDeleteDocumentMutation()
    const [triggerGetNegotiation, {data: negotiationData, isLoading: negotiationLoading, isFetching: negotiationFetching}] = useLazyGetNegotiationDetailQuery()
    const [isModalUploadVisible, setIsModalUploadVisible] = useState<boolean>(false)
    const [isSearchModalVisible, setIsSearchModalVisible] = useState<boolean>(false)
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState<boolean>(false)
    const [treeStructure, setTreeStructure] = useState<TreeStructureModel[]>()
    const [fileList, setFileList] = useState<TreeStructureModel[]>([])
    const [allFiles, setAllFiles] = useState<DocumentListModel[]>([])
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [selectedDocument, setSelectedDocuments] = useState<CustomDataNode[]>([])
    const [onlyFilesMode, setOnlyFilesMode] = useState<boolean>(false)
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [destinationFolder, setDestinationFolder] = useState<string | null>(null)
    const [dropFolderKey, setDropFolderKey] = useState(null)
    const [isRore, setIsRore] = useState<boolean>(false)
    const [showUploadReportDrawer, setShowUploadReportDrawer] = useState<boolean>(false)
    const [allUploadedDocuments, setAllUploadedDocuments] = useState<{ name: string; status: string; msg: string}[]>([])
    const [numDocUploded, setNumUploaded] = useState<number>(0)
    const [numDocNotUploded, setNumNotUploaded] = useState<number>(0)
    const negotiationDetails = location.state as {status: number, policyNumber: string, contractor: string, lob: string, lobUuid: string, underwriter: string, receptionDate: string, effectiveDate: string, expiringDate: string, uwYear: number, broker: string, isia: boolean}

    const readOnly = !(useCanUploadNegotiationDocuments(negotiationDetails?.lobUuid || negotiationData?.lob.uuid))


    useEffect(()=> {
        getFoldersLabel().unwrap()
        if (negotiationId)
            getHistory({negotiation: negotiationId}).unwrap()
    }, [getFoldersLabel])

    useEffect(()=> {
        if (!negotiationDetails && negotiationId) {
            triggerGetNegotiation(negotiationId).unwrap()
                .then((res)=> {
                    if (res.state === States.Rore)
                        setIsRore(true)
                })
        }
    }, [negotiationData, negotiationId, negotiationDetails, triggerGetNegotiation])

    useEffect(() => {
        getFoldersStructure().unwrap()
            .then((response)=> setTreeStructure(response))
            .catch((e: any) => {
                message.error('Errore nel caricamento dei file')
                console.error('fetchFoldersStructure', e)
            })
    }, [getFoldersStructure, getFoldersLabel])

    const updateFileList = useCallback((newFiles: any) => {
        const treeFiles = newFiles.map((el: GetDocumentsModel) => {
            return {title: el.name, key: el.uuid, path: el.path, selectable: true, isLeaf: true, canUpload: true, userCreation: el.user_creation, userEdit: el.user_edit, dateEdit: el.date_edit, dateCreation: el.date_creation}
        })
        const treeFilesID = newFiles.map((el: GetDocumentsModel) => {
            return el.uuid
        })
        setFileList((prevState)=> {
            let newFileList = cloneDeep(prevState)
            newFileList = newFileList.filter(el => !treeFilesID.includes(el.key))
            return [...newFileList, ...treeFiles]
        })
    }, [])

    const filesTitle = useMemo(() => (node: GetDocumentsModel) => {
        const folder = foldersLabelData?.find(el=> el.value === node.path)
        let folderName
            if (folder)
                folderName = folder.label
        return <div>
            <p>{node.name}</p>
            <p style={{fontSize: "12px", color: "grey"}}>{folderName}</p>
        </div>
    }, [foldersLabelData])

    const fileIcon = () => {
        return <div style={{position: "absolute", top: "-1px"}}><FileOutlined/></div>
    }

    const fetchDocumentsData = useCallback((_path?: string) => {
        if (onlyFilesMode)
            return getDocuments({negotiation_id: negotiationId}).unwrap()
                .then((response) => {
                    const files = response.filter(el => el.is_valid).map((el, index) => {
                            return {title: filesTitle(el), fileName: el.name, key: el.uuid, path: el.path, selectable: true, isLeaf: true, icon: fileIcon(), userCreation: el.user_creation, userEdit: el.user_edit, dateEdit: el.date_edit, dateCreation: el.date_creation }
                    })
                    setAllFiles(files)
                })
        else
            return getDocuments({negotiation_id: negotiationId, path: _path}).unwrap()
                .then((response) => updateFileList(response))
                .catch((e: any) => {
                    message.error('Impossibile caricare i file')
                    console.error('fetchDocumentsData', e)
                })
    }, [filesTitle, getDocuments, negotiationId, onlyFilesMode, updateFileList])

    const composeTree = useCallback((tree: TreeStructureModel, leaves: TreeStructureModel[]) => {
        const treeCopy = _.cloneDeep(tree)
        for (let i = 0; i < treeCopy.children.length; i++)
            treeCopy.children[i] = composeTree(treeCopy.children[i], leaves)
        let filesToAppend = leaves.filter((el: TreeStructureModel) => el.path === treeCopy.key)
        treeCopy.children = [...treeCopy.children, ...filesToAppend]
        return treeCopy
    }, [])

    useEffect(() => {
        if (foldersStructureData)
            setTreeStructure(foldersStructureData.map(el=> composeTree(el, fileList)))
    }, [foldersStructureData, fileList, composeTree, expandedKeys, selectedKeys])

    const saveInAws = useCallback((data: any, file: File) => {
        const postData = new FormData()
        for (const key in data.fields)
            postData.append(key, data.fields[key])
        postData.append('file', file)
        return axios.post(data.url, postData)
    }, [])

    const saveFiles = async (file: File, path: string, fileName: string | undefined) => {
        try {
            const responseSaveDocs = await saveDocument({path: path, name: fileName, negotiation: negotiationId, content_type: file.type}).unwrap()
            await saveInAws(responseSaveDocs, file)
            await updateDocument({uuid: responseSaveDocs.uuid, data: {is_valid: true, negotiation: negotiationId}}).unwrap()
                .then(res=> {
                    setAllUploadedDocuments(prevState => {
                        const updatedState = cloneDeep(prevState)
                        const foundElement = updatedState.find(el=> el.name === fileName)
                        if (foundElement) {
                            foundElement.status = "accepted"
                            foundElement.msg = "Caricato con successo"
                        }
                        return updatedState
                    })
                })
            if (onlyFilesMode)
                return await fetchDocumentsData()
            else if (expandedKeys.includes(path))
                return await fetchDocumentsData(path)
        } catch {
            setAllUploadedDocuments(prevState => {
                const updatedState = cloneDeep(prevState)
                const foundElement = updatedState.find(el => el.name === fileName)
                if (foundElement) {
                    foundElement.status = "rejected"
                    foundElement.msg = "Errore nel caricamento"
                }
                return updatedState
            })
            console.error('saveFiles')
            return new Error('errore')
        }
    }

    const handleUploadDocument = async (files: UploadDoc[], realFiles: File[][]) => {
        setShowUploadReportDrawer(true)
        const documents = files.map(el => ({
            name: el.name,
            status: "loading",
            msg: ""
        }))
        setAllUploadedDocuments(documents)
        const results = []
        setLoadingUpload(true)
        if (realFiles.length) {
            for (let i = 0; i < realFiles[0].length; i++)
                results.push(saveFiles(realFiles[0][i], files[i].dirType, files[i].fileName))
        }
        Promise.all(results).then(res => setLoadingUpload(false))
    }

    const handleDeleteDocument = useCallback((docToDelete: CustomDataNode[]) => {
        setSelectedKeys([])
        setSelectedDocuments([])
        for (let i in docToDelete) {
            deleteDocument({negotiation_uuid: negotiationId, uuid: docToDelete[i].key as string}).unwrap()
                .then(() => {
                    message.success((!onlyFilesMode ? docToDelete[i].title : docToDelete[i].fileName) + " eliminato" )
                })
                .catch((e: any) => {
                    message.error("Impossibile eliminare " + docToDelete[i].title)
                    console.error(e)
                })
        }
        setFileList(prevState => prevState.filter(el => !(docToDelete.map(el => el.key).includes(el.key))))
        setAllFiles(prevState => prevState.filter(el => !(docToDelete.map(el => el.key).includes(el.key))))
    }, [deleteDocument, negotiationId, onlyFilesMode])

    const handleRenameDocument = useCallback((fileName: string, uuid: string) => {
        setSelectedKeys([])
        setSelectedDocuments([])
        updateDocument({uuid: uuid, data: {name: fileName, negotiation: negotiationId}}).unwrap()
            .then(() => {
                message.success("Nome del documento aggiornato in " + fileName)
                fetchDocumentsData()
            })
            .catch((e: any) => {
                message.error("Impossibile rinominare il file")
                console.error(e)
            })
    }, [fetchDocumentsData, negotiationId, updateDocument])

    const handleChangeTypeDocument = useCallback((dirType: string, documents: TreeStructureModel[]) => {
        setSelectedKeys([])
        setSelectedDocuments([])
        if (!onlyFilesMode && !expandedKeys.includes(dirType)){
            setSelectedKeys([])
            setSelectedDocuments([])
        }
        for (let i in documents) {
            updateDocument({uuid: documents[i].key as string, data: {path: dirType, negotiation: negotiationId}}).unwrap()
                .then(()=> {
                    message.success((!onlyFilesMode ? documents[i].title : documents[i].fileName) + ' spostato di cartella')
                    fetchDocumentsData()
                    fetchDocumentsData(documents[i].path as string)
                    if (expandedKeys.includes(dirType))
                        fetchDocumentsData(dirType)
                })
                .catch((e: any)=> {
                    message.error("Impossibile spostare " + (!onlyFilesMode ? documents[i].title : documents[i].fileName))
                    console.error(e)
                })
        }
    }, [expandedKeys, fetchDocumentsData, negotiationId, onlyFilesMode, updateDocument])

    useEffect(() => {
        fetchDocumentsData()
    }, [fetchDocumentsData, getDocuments, negotiationId, onlyFilesMode])

    const handleOnExpand = useCallback((expandedKeys: any, {expanded, node}: any) => {
        setSelectedKeys([])
        setSelectedDocuments([])
        setExpandedKeys((prevState)=> [...expandedKeys])
        if (expanded && node.children.length === 0)
            fetchDocumentsData(node.key as string)
    }, [fetchDocumentsData])

    const handleDoubleClick = (event: any, node: any) => {
        if (node.isLeaf)
            getUrl({negotiation_uuid: negotiationId, uuid: node.key}).unwrap()
                .then((response) => {window.open(response.url)})
                .catch((e:any)=> {
                    message.error("Impossibile aprire il file")
                    console.error("Open file", e)
                })
    }

    const onSelect: TreeProps['onSelect'] = (keys: any[], info: any) => {
        let leafElements: CustomDataNode[] = []
        info.selectedNodes?.forEach((node: CustomDataNode) => {
            if (node.isLeaf)
                leafElements.push(node)
        })
        setSelectedDocuments(leafElements)
        setSelectedKeys([...keys])
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            setSelectedKeys([])
            setSelectedDocuments([])
        }
        if (event.key === 'Delete' && selectedDocument.length !== 0 && !readOnly)
            setIsModalRemoveVisible(true)
    }

    const handleRightClick = (info: { event: any; node: any; }) => {
        if (!selectedKeys.includes(info.node.key) && info.node.isLeaf) {
            setSelectedDocuments([info.node])
            setSelectedKeys([info.node.key])
        }
    }

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
        if (dropFolderKey) {
            setShowUploadReportDrawer(true)
            const filesRejected = fileRejections.map(el => ({
                name: el.file.name,
                status: "rejected",
                msg: (el.errors[0].code === "file-too-large") ? "La dimensione supera quella consentita" : (el.errors[0].code === "file-invalid-type") ? "Estensione non accettata" : ""
            }))
            const filesAccepted = acceptedFiles.map(el => ({name: el.name, status: "loading", msg: ""}))
            const documents = filesAccepted.concat(filesRejected)
            setAllUploadedDocuments(documents)
            const results = []
            setLoadingUpload(true)
            for (let i = 0; i < acceptedFiles.length; i++) {
                results.push(saveFiles(acceptedFiles[i], dropFolderKey, acceptedFiles[i].name))
            }
            Promise.all(results).then(res => {
                setLoadingUpload(false)
                setShowUploadReportDrawer(true)
            })
        }
        setDropFolderKey(null)
    }, [dropFolderKey])

    const onDragOver = useCallback((event: any) => {
        setShowUploadReportDrawer(false)
        if (!onlyFilesMode && !readOnly) {
            event.preventDefault()
            const test = event.target.getAttribute("data-idrow")
            if (!event.target.id) {
                setExpandedKeys(prevState => {
                    if (!prevState.includes(test))
                        return [...prevState, test]
                    else
                        return [...prevState]
                })
                setDropFolderKey(null)
            }
            else {
                setDropFolderKey(event.target.id)
            }
        }
    }, [onlyFilesMode, readOnly])

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.addEventListener('mouseleave', function (event) {
                setDropFolderKey(null)
            })}
    }, [])

    useEffect(() => {
        if (dropFolderKey) {
            const folder = foldersLabelData?.find(el => el.value === dropFolderKey)
            if (folder)
                setDestinationFolder(folder.label)
        }
    }, [foldersLabelData, dropFolderKey])

    useEffect(() => {
        const uploaded = allUploadedDocuments.reduce((acc, obj) => {
            if (obj.status === "accepted")
                return acc + 1;
            return acc;
        }, 0)
        const notUploaded = allUploadedDocuments.reduce((acc, obj) => {
            if (obj.status === "rejected")
                return acc + 1;
            return acc;
        }, 0)
        setNumUploaded(uploaded)
        setNumNotUploaded(notUploaded)
    }, [allUploadedDocuments])

    const changeNegotiation = useCallback((negID: string)=> {
        setSelectedKeys([])
        setSelectedDocuments([])
        setExpandedKeys([])
        setFileList([])
        navigate((`/documents/${negID}/`))
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        onDragOver: onDragOver,
        noClick: true,
        maxSize: 50 * 1024 * 1024, //50MB
        accept: {'image/png': ['.svg', '.png', '.jpeg', '.jpg', '.gif', '.webp', '.tiff', '.psd', '.raw', '.bmp', '.heif'], 'application/pdf': ['.pdf', '.xls', '.xlsx', '.xlsm', '.doc', '.docx', '.docm', '.dwg', '.odt', '.ods', '.p7c', '.zip', '.msg'], 'message/rfc822': ['.eml']}
    })

    const dropDownMenu = useMemo(() => <DropDownMenu selectedDocument={selectedDocument}
                                                     readOnly={readOnly}
                                                     onlyFilesMode={onlyFilesMode}
                                                     negotiation={negotiationId}
                                                     handleDelete={handleDeleteDocument}
                                                     options={foldersLabelData}
                                                     handleRename={handleRenameDocument}
                                                     handleChangeDir={handleChangeTypeDocument}></DropDownMenu>, [handleChangeTypeDocument, handleDeleteDocument, handleRenameDocument, negotiationId, onlyFilesMode, selectedDocument])

    return <div tabIndex={-1} onKeyDown={handleKeyDown}>
        <RemoveDocumentModal document={selectedDocument} remove={handleDeleteDocument} visible={isModalRemoveVisible} close={() => setIsModalRemoveVisible(false)}/>
        <UploadDocumentModal upload={handleUploadDocument} visible={isModalUploadVisible}
                             close={() => setIsModalUploadVisible(false)} selectOptions={foldersLabelData}/>
        <SearchDocumentModal visible={isSearchModalVisible} close={() => setIsSearchModalVisible(false)} negotiation={negotiationId} options={foldersLabelData}
                             setExpandedKeys={setExpandedKeys}
                             setSelectedKeys={setSelectedKeys} fetchDocument={fetchDocumentsData} selectedDocument={setSelectedDocuments}/>
        <Drawer title={
            <Row justify={"space-between"}>
                <Col>{allUploadedDocuments.some(el=> el.status === "loading") ? <div>Caricamento documenti... <Spin size={"small"}/></div> : <div>{"Caricati ("+numDocUploded+"), non caricati ("+numDocNotUploded+")"}</div>}</Col>
                <Col>
                    <Tooltip placement={"top"} title={"Chiudi"}>
                        <CloseOutlined onClick={() => setShowUploadReportDrawer(false)}/>
                    </Tooltip>
                </Col>
            </Row>}
                mask={false}
                closable={false}
                style={{width: "35vw", marginLeft: 100 - 37 + "vw"}}
                open={showUploadReportDrawer}
                placement={"bottom"}
                onClose={() => {
                    setShowUploadReportDrawer(false)
                    setAllUploadedDocuments([])
                }}>
            {allUploadedDocuments.map(el => {
                return <Tooltip placement={"left"} title={el.msg}>
                    <Row justify={"space-between"} style={{borderBottom: "1px solid gainsboro"}}>
                        <Col span={22}>
                            <div className={"ellipsis-name"}>{el.name}</div>
                        </Col>
                        <Col span={2}>
                            {el.status === "loading" ? <Spin size={"small"}/> :
                                el.status === "accepted" ? <CheckCircleOutlined style={{color: "green"}}/> :
                                    <CloseCircleOutlined style={{color: "red"}}/>}
                        </Col>
                    </Row>
                </Tooltip>
            })}
        </Drawer>
        {!isRore && <Dropdown overlay={dropDownMenu} trigger={['contextMenu']} disabled={selectedDocument.length === 0}>
        <Row style={{paddingTop: '1.5rem '}} justify={"space-evenly"} gutter={[0, 20]}>
            <Col lg={{span: 17, order: 1}} xs={{span: 24, order: 2}}>
                <div {...getRootProps()} >
                    <input {...getInputProps()} />
                    <Card
                        style={{
                            height: '88vh',
                                overflow: "scroll",
                                backgroundColor: `${dropFolderKey ? "#E6F7FF" : ""}`,
                                border: `${dropFolderKey ? "1px solid blue" : ""}`
                            }}
                            title={<><Row justify={'space-between'} align={'middle'}>
                                <Col>
                                    <Button type='text' icon={<LeftOutlined style={{color: '#1890FF'}}/>} onClick={() => navigate(`../negotiations/${negotiationId}/`)}>
                                    <span style={{color: '#1890FF'}}>Torna alla trattativa</span>
                                </Button>
                            </Col>
                        </Row>
                            <Row justify={'space-between'} align={'middle'} wrap={true}>
                                <Col><Space><Title level={4}>Documenti</Title><DocumentHelpPopover/></Space></Col>
                                <Col>
                                    <Button type={'default'} icon={<SearchOutlined/>} onClick={() => setIsSearchModalVisible(true)}>
                                        Cerca
                                    </Button>
                                    {!readOnly &&
                                        <Button type={'primary'} icon={<FileAddOutlined/>} style={{marginLeft: "10px"}} onClick={() => {
                                            setIsModalUploadVisible(true)
                                            setShowUploadReportDrawer(false)
                                        }}>
                                            Carica
                                        </Button>}
                                    <Button style={{marginLeft: "10px"}} onClick={() => {
                                        setOnlyFilesMode(prevState => !prevState)
                                        setSelectedDocuments([])
                                        setSelectedKeys([])
                                        setExpandedKeys([])
                                    }
                                    }>{!onlyFilesMode ? <UnorderedListOutlined/> : <FolderOpenOutlined/>}</Button>
                                </Col>
                            </Row></>}
                    >
                        <Spin spinning={loadingUpload || isDocumentLoading || isDocumentFetching} tip={"Caricamento documenti..."}>
                            <Col xs={0} sm={24} style={{marginLeft: onlyFilesMode ? "48px" : "100px"}}><Row justify={"space-between"} align={"middle"}
                                                                                                            style={{padding: "0 4px 0 4px"}}>
                                <Col lg={12} sm={24}>Nome</Col>
                                <Col xl={4} xs={0}>Data aggiunta</Col>
                                <Col lg={4} xs={0}>Aggiunto da</Col>
                            </Row></Col>
                            <Col xs={0} sm={24}><Divider style={{margin: "5px 5px"}}/></Col>
                            <Tree.DirectoryTree
                                style={dropFolderKey ? {backgroundColor: "#E6F7FF"} : {}}
                                onDoubleClick={handleDoubleClick}
                                multiple={true}
                                treeData={!onlyFilesMode ? treeStructure : allFiles}
                                expandedKeys={expandedKeys}
                                expandAction={"click"}
                                onExpand={handleOnExpand}
                                onSelect={onSelect}
                                onRightClick={handleRightClick}
                                selectedKeys={selectedKeys}
                                titleRender={(n) => {
                                    return <TitleRender n={n} onDrop={onDrop} onDragOver={onDragOver}></TitleRender>
                                }}
                            ></Tree.DirectoryTree>
                            {(allFiles.length === 0 && onlyFilesMode) ?
                                <Row justify={"center"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nessun documento"}></Empty></Row> : ""}
                        </Spin>
                    </Card></div>
                </Col>
                <Col lg={{span: 6, order: 2}} xs={{span: 24, order: 1}}>
                    {negotiationDetails ? <DocumentSidebar status={negotiationDetails.status} contractor={negotiationDetails.contractor}
                                                           policyNumber={negotiationDetails.policyNumber}
                                                           lob={negotiationDetails.lob}
                                                           underwriter={negotiationDetails.underwriter}
                                                           receptionDate={negotiationDetails.receptionDate}
                                                           effectiveDate={negotiationDetails.effectiveDate}
                                                           expiringDate={negotiationDetails.expiringDate}
                                                           uwYear={negotiationDetails.uwYear}
                                                           changeNegotiation={changeNegotiation}
                                                           uwYearOptions={historyData}
                                                           broker={negotiationDetails.broker} isia={negotiationDetails.isia}
                        /> :
                        <DocumentSidebar status={negotiationData?.state} contractor={negotiationData?.contractor?.name}
                                         policyNumber={negotiationData?.policy_number} uwYearOptions={historyData}
                                         lob={negotiationData?.lob?.name} underwriter={negotiationData?.underwriter?.user_full_name}
                                         receptionDate={negotiationData?.reception_date} effectiveDate={negotiationData?.policy_effective_date}
                                         expiringDate={negotiationData?.policy_expiring_date} uwYear={negotiationData?.uw_year} changeNegotiation={changeNegotiation}
                                         broker={negotiationData?.broker?.name} isia={negotiationData?.is_isia} loading={negotiationLoading || negotiationFetching}
                        />}
                </Col>
                <div className={'upload-document-message'} style={{display: dropFolderKey ? "" : "none"}}>
                    <Row justify={"center"} style={{marginTop: "2px"}}><CloudUploadOutlined style={{fontSize: "40px"}}/></Row>
                    <Row justify={"center"}><h4>Rilascia file per caricarli in</h4></Row>
                    <Row justify={"center"}><h3>{destinationFolder}</h3></Row>
                </div>
            </Row>
        </Dropdown>}
            {isRore && <div style={{paddingTop: "15rem"}}>
                <Row justify={"center"}><h3>Non è possibile accedere ai documenti per una trattativa in stato Rore </h3></Row>
                <Row justify={"center"}>
                                <Button type='text' onClick={() => navigate(`../negotiations/${negotiationId}/`)}>
                                    <span style={{color: '#1890FF'}}>Torna alla trattativa</span>
                                </Button>
            </Row></div>}
    </div>
}