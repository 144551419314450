import {Button, Col, Collapse, Modal, Row, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import DatePicker from "../DatePicker";
import {
    useCreateBudgetMutation, useGetBudgetGroupsQuery, useGetBudgetsQuery,
} from "../../redux/api/budgetsApiSlice";
import GapToPlanForm from "./GapToPlanForm";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {CaretRightOutlined} from '@ant-design/icons';
import {Budget} from "../../types/budgets";
import {canModifyGapToPlan} from "../../utils/permission";
import {skipToken} from "@reduxjs/toolkit/dist/query";


export default function GapToPlanNegotiationsModal({
                                                       showModalGapToPlanModifier,
                                                       setShowModalGapToPlanModifier
                                                   }: { showModalGapToPlanModifier: boolean, setShowModalGapToPlanModifier: React.Dispatch<React.SetStateAction<boolean>> }) {


    const [selectedYear, setSelectedYear] = useState<dayjs.Dayjs>(dayjs(Date.now()))
    const {
        data: budgets,
        isFetching: budgetsIsFetching,
    } = useGetBudgetsQuery(showModalGapToPlanModifier ? {uw_year: selectedYear.year()} : skipToken, {refetchOnMountOrArgChange: true})

    const {
        data: groupBudgets,
        isFetching: groupBudgetsIsFetching
    } = useGetBudgetGroupsQuery(undefined, {skip: !showModalGapToPlanModifier})
    const [createBudget, {isLoading}] = useCreateBudgetMutation()
    const [formBudgets, setFormBudgets] = useState<Budget[]>([])

    const user = useSelector(selectUser)

    useEffect(() => {
        if (budgets) {
            setFormBudgets(budgets)
        }
    }, [budgets])

    return <Modal
        title={'Modifica Budget'} open={showModalGapToPlanModifier}
        onOk={() => setShowModalGapToPlanModifier(false)}
        onCancel={() => setShowModalGapToPlanModifier(false)}
        footer={false}
        destroyOnClose
    >
        <Row justify={"end"}>
            <Col style={{border: '1px solid #D9D9D9'}}>
                <DatePicker.YearPicker clearIcon={false}
                                       value={selectedYear}
                                       onChange={value => {
                                           if (value) {
                                               setSelectedYear(value)
                                           }
                                       }}
                                       style={{width: '7.5rem'}}
                                       bordered={false}/>
            </Col>
        </Row>
        {budgetsIsFetching || groupBudgetsIsFetching ?
            <Skeleton/>
            :
            <Collapse
                expandIcon={({isActive}) => <CaretRightOutlined
                    rotate={isActive ? 90 : 0}/>}
                style={{margin: '1rem 0'}}>
                {groupBudgets?.map(groupBudget => {
                    const foundBudget = formBudgets?.find(b => b.budget_group.uuid === groupBudget.uuid)
                    if (foundBudget) {
                        return <Collapse.Panel
                            style={{padding: '0 0.5rem'}}
                            key={groupBudget.uuid}
                            header={<div style={{padding: '0.5rem 0'}}>{groupBudget.name}</div>}>
                            <GapToPlanForm budget={foundBudget}/>
                        </Collapse.Panel>
                    } else {
                        return <Collapse.Panel
                            collapsible={'disabled'}
                            style={{padding: '0 0.5rem'}}
                            key={groupBudget.uuid}
                            header={
                                <Row
                                    justify={'space-between'}
                                    align={'middle'}
                                    style={{padding: '0.5rem 0'}}>
                                    <Col>
                                        {groupBudget.name}
                                    </Col>
                                    <Col>
                                        {!!user.usertypes.length && canModifyGapToPlan(user.usertypes.map(el => el.code)) &&
                                            <Button
                                                type={'primary'}
                                                loading={isLoading}
                                                onClick={async () => {
                                                    const newBudget = await createBudget({
                                                        budget_group: groupBudget.uuid,
                                                        uw_year: selectedYear.year(),
                                                        total_goal: 0,
                                                        direct_isa_goal: 0,
                                                        isia_goal: 0
                                                    }).unwrap()
                                                    setFormBudgets(prevState => [...prevState, newBudget])

                                                }}>
                                              Crea
                                            </Button>}
                                    </Col>
                                </Row>}
                        />
                    }
                })}
            </Collapse>}
    </Modal>
}