import {NegotiationModel} from "../../../../types/negotiations";
import {GetAgreementRow, useGetAgreementRowsQuery} from "../../../../redux/api/agreementRowApiSlice";
import {notification, Space, Table, TablePaginationConfig} from "antd";
import React, {useMemo, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {euro} from "../../../../utils/formatters";
import {Link} from "react-router-dom";
import EditAgreementRow from "./EditAgreementRow";
import DeleteAgreementRow from "./DeleteAgreementRow";
import useCanModifyForm from "../../../../hooks/permissions/useCanModifyForm";

const MASTER_NEGOTIATION = "master_negotiation"
export default function AgreementRows({negotiation, disabled}: { negotiation: NegotiationModel, disabled: boolean }) {

    const {uuid} = negotiation
    const canModifyForm = useCanModifyForm(negotiation.lob.uuid)
    const isLobWithFunds = !!negotiation.lob?.has_funds
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        simple: true,
        onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    });
    const {data, isFetching, isUninitialized} = useGetAgreementRowsQuery({
        negotiationUuid: uuid,
        page: pagination.current,
        page_size: pagination.pageSize
    })
    const columns: ColumnsType<GetAgreementRow> = useMemo(() => [
        {
            key: 'contractor',
            dataIndex: 'contractor',
            title: 'Contraente',
            render: (value, record) => record.contractor.name,
        },
        ...isLobWithFunds ? [{
            key: 'fund',
            dataIndex: 'fund',
            title: 'Cassa assistenza',
            render: (value: GetAgreementRow["fund"]) => value?.name ?? 'n.d.'
        }] : [],
        {
            key: 'quoted_premium',
            dataIndex: 'quoted_premium',
            title: 'Premio (al 100%)',
            align: 'right',
            render: (value) => euro(value)
        },
        {
            key: 'actions',
            title: 'Azioni',
            align: 'right',
            render: (value, record) => {
                if (record.uuid === MASTER_NEGOTIATION) {
                    return <strong>Trattativa master</strong>
                }

                let element: JSX.Element
                if (record.negotiation_agreement) {
                    element = <Link
                        to={`/negotiations/${record.negotiation_agreement}`}
                        onClick={() => notification.open({
                            message: 'Attenzione, sei stato reindirizzato a una nuova trattativa.',
                            type: 'warning',
                            duration: 0
                        })}
                    >
                        Vai alla trattativa...
                    </Link>
                } else {
                    element = canModifyForm ? <EditAgreementRow
                        disabled={disabled}
                        canHaveFund={isLobWithFunds}
                        negotiationUuid={negotiation.uuid}
                        agreementRow={record}/> : <></>
                }
                return <Space
                    align={'center'}
                >
                    {element}
                    {canModifyForm && <DeleteAgreementRow
                        disabled={disabled}
                        agreementRowUuid={record.uuid}
                        negotiationUuid={negotiation.uuid}
                    />}
                </Space>
            }
        },
    ], [disabled, isLobWithFunds, negotiation.uuid, canModifyForm])

    return <>
        <Table
            <GetAgreementRow>
            scroll={{x: true}}
            loading={isFetching || isUninitialized}
            dataSource={(data?.results && negotiation) ? [
                    {
                        uuid: MASTER_NEGOTIATION,
                        contractor: {uuid: negotiation.contractor?.uuid ?? "", name: negotiation.contractor?.name ?? ""},
                        fund: negotiation.fund ? {uuid: negotiation.fund.uuid, name: negotiation.fund.name ?? ""} : null,
                        quoted_premium: negotiation.quoted_premium ?? 0,
                        negotiation_agreement: negotiation.uuid,
                    },
                    ...data?.results
                ]
                : undefined}
            pagination={{
                total: data?.count,
                ...pagination,
            }}
            columns={columns}
            rowKey={'uuid'}
            rowClassName={(record, index) => record.uuid === MASTER_NEGOTIATION ? 'ant-table-row-selected ant-table-row-selected-header' : ""}
            summary={() => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}><b>Totale Premi</b></Table.Summary.Cell>
                            {isLobWithFunds && <Table.Summary.Cell index={1}></Table.Summary.Cell>}
                            <Table.Summary.Cell align={'right'}
                                                index={+isLobWithFunds + 1}><b>{euro(data?.premiums ?? 0)}</b></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>)
            }}
        />
    </>
}